/* History */

.Workflow .History {
  margin: 20px auto;
}

.Workflow .History th {
  text-align: center;
  padding-top: 15px;
  text-decoration: underline;
}

.Workflow .History tbody:first-of-type th {
  padding-top: 0;
}

.Workflow .History tr td:first-of-type {
  padding-right: 10px;
}

.Workflow .History tbody tr:last-of-type {
  font-weight: 600;
}

.Workflow .History tbody.total tr:last-of-type td:last-of-type,
.Workflow .History tbody.present tr:last-of-type td:last-of-type {
  color: rgb(39, 174, 96);
}

/* Chart */

.Workflow .Chart {
  margin: 20px auto;
  text-align: center;
}

.Workflow .Chart .zone,
.Workflow .Chart .subworkflow {
  vertical-align: top;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}

.Workflow .Chart .subworkflow {  /* Show parallel sub-workflows horizontally */
  white-space: nowrap;
}

.Workflow .Chart .zone:last-child,
.Workflow .Chart .subworkflow>.subworkflow {
  margin-bottom: 0;
}

.Workflow .Chart .zone {
  padding: 10px 15px;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, .1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .04);
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 150px;
  color: rgba(0, 0, 0, .5);
  font-weight: 600;
}

.Workflow .Chart .subworkflow .zone {  /* Shrink subworkflow zones on mobile */
  max-width: 150px;  /* Same maximum size as zones not in subworkflow */
  min-width: 100px;
  width: 30vw;  /* Scale zone width based on screen width */
}

.Workflow .Chart .zone::after {
  content: "Not Required";
  display: block;
  font-weight: normal;
}

.Workflow .Chart .zone.required {
  color: inherit;
}

.Workflow .Chart .zone.required::after {
  content: "Required";
}

.Workflow .Chart .zone.completed {
  background-color: #eee;
  color: rgba(0, 0, 0, .5);
}

.Workflow .Chart .zone.completed::after {
  content: "Completed";
  font-weight: normal;
}

.Workflow .Chart .zone.active {
  background-color: #DBF5E3;
  color: inherit;
}

.Workflow .Chart .zone.active::after {
  content: "In Progress";
}

.Workflow .Chart .zone.linear {
  display: block;
}

.Workflow .Chart .zone.parallel {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.Workflow .Chart .subworkflow {
  background-color: rgba(63, 154, 247, .08);
  border: 1px solid rgba(63, 154, 247, .15);
  display: inline-block;
  vertical-align: top;
  border-radius: 10px;
}

.Workflow .Chart .subworkflow .zone,
.Workflow .Chart .subworkflow .subworkflow {
  margin: 10px;  /* Add space between items in subworkflow */
}

.Workflow .Chart .subworkflow.parallel > .zone,
.Workflow .Chart .subworkflow.parallel > .subworkflow {
  margin-left: 0;  /* Don't have double-space between parallel zones/subworkflows */
}

.Workflow .Chart .subworkflow.parallel > .zone:first-child,
.Workflow .Chart .subworkflow.parallel > .subworkflow:first-child {
  margin-left: 10px;  /* Don't have double-space between parallel zones/subworkflows */
}

/* Loading and empty states */

.Workflow .History.no_items,
.Workflow .History.loading,
.Workflow .Chart.no_items,
.Workflow .Chart.loading {
  opacity: .5;
  text-align: center;
  font-weight: 600;
}
